$primary-green: #093A1D;

.short-event {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any content that overflows the container */
}

.long-event {
  overflow: hidden; /* Hide any content that overflows the container */
}

.fc-event-main {
  cursor: pointer;
}

.fc-event {
  &.event {

    &-waiting {
      background-color: grey !important;
      border-color: grey !important;

    }

    &-expected {
      background-color: $primary-green !important;
      border-color: $primary-green !important;

    }

    &-unavailable {
      background-color: purple !important;
      border-color: purple !important;

    }

    &-modified {
      background-color: $primary-green !important;
      background-image: repeating-linear-gradient(
                      45deg,
                      $primary-green,
                      $primary-green 10px,
                      rgba(white, 0.6) 10px,
                      rgba(white, 0.6) 20px
      ) !important;
      border-color: $primary-green !important;
    }

    &-error {
      background-color: red;
      border-color: red !important;
    }
  }
}