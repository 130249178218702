


.formButtons{
  padding-top: 1rem;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-left: 0.5rem;
  }
}