.container {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 2rem;
    background-color: white;
    height: max-content;

    grid-area: content;

    &-calendar{
        width: 100%;
    }
}

.sideBarContainer{
    grid-area: sidebar;
}

html {
    height: 100%;
}

body{
    background-color: #E9E8E8!important;
    height: 100%;
}

#root {
    height: 100%;
}

.navbar{
    background-color: #093A1D;
}

.app{

    $gap: 2rem;
    display: grid;
    height: 100%;
    direction: ltr;
    column-gap: $gap;
    margin-right: $gap;

    grid-template-columns: 1fr 6fr;
    grid-template-areas:
    "sidebar content";
}

.optionListContainer{
    z-index: 99 !important;
}

.date-time-range-group{
    margin-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
}

.table-column-header div {
    text-transform: capitalize;
    font-weight: bold;
}
