.chart-container {
    font-family: 'Roboto', sans-serif; /* More professional looking font */
    background-color: white;
  }

  .chart-border {
    border: 1px solid lightgray; /* Adjust color and width as needed */
  }
  
  
  .custom-tooltip {
    background-color: #f5f5f5;
    padding: 10px;
    border: 2px solid #32CD32;
    border-radius: 15px;
    font-size: 0.8em;
    box-shadow: 0px 0px 10px rgba(50,205,50,0.3);
  }
  
  .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid-vertical line {
    stroke: #f5f5f5;
  }
  
  .recharts-cartesian-axis-ticks text {
    font-size: 0.8em;
    fill: #666;
  }

  .recharts-cartesian-axis-tick-value {
    white-space: nowrap;
  }