@import "src/shared";

.sidebar {
  height: 100%;
  color: white;

  &-footer {
    width: 100%;

    &-logout {
      width: 100%;


      &:hover {
        background-color:  #198754 !important;
        border-radius: 0;
      }
      display: inline-flex !important;

      &__text {
        margin-left: 1rem;
      }
    }

    &-contact-us{
      width: 100%;

      &:hover {
        background-color:  #198754 !important;
        border-radius: 0;
      }

      display: inline-flex !important;

      a {
        color:white;
        text-decoration: none !important;
      }

      &__text {
        margin-left: 1rem;
      }
    }
  }

  &-logo{
    text-align: center;
    padding: 1rem;
    background-color: $primary;
  }

  &-link {
    .active {
      background-color: #198754;
      color: #C3CDC6;
    }


    a{
      &:hover {
        background-color:  #198754 !important;
      }
    }
  }
}