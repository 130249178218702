.buildingStatus {
    &-alert{
        margin-top: 1rem;
        &-message{
            span{
                margin-right: 1rem;
            }
        }
    }

}

